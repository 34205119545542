<app-nav-menu></app-nav-menu>
<router-outlet></router-outlet>
<img src="/assets/images/strategies/equity/equity-header.jpg" style="display:none;" />
<img src="/assets/images/strategies/fixed/fixed-header.jpg" style="display:none;" />
<img src="/assets/images/strategies/structured/structured-header.jpg" style="display:none;" />
<img src="/assets/images/home/globe/slide2-1.jpg" style="display:none;" />
<img src="/assets/images/home/globe/slide2-2.jpg" style="display:none;" />
<img src="/assets/images/home/globe/slide2-3.jpg" style="display:none;" />
<img src="/assets/images/home/globe/slide3-1.jpg" style="display:none;" />
<img src="/assets/images/home/globe/slide3-2.jpg" style="display:none;" />
<img src="/assets/images/home/globe/slide3-3.jpg" style="display:none;" />