import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { DataService } from './services/data.service';

declare let window: any;
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private data: DataService) {}

  ngOnInit() {
    this.router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((r: any) => {
      window.gtag('config', 'UA-118004927-3', { page_path: r.url });
      clearAllBodyScrollLocks();
      window.scrollTo(0, 0);
    });

    setTimeout(() => {
      this.data.articles.then((articles) => {
        const img = new Image();
        img.src = articles[0].image_url_feature;
      });
    }, 1000);
  }
}
