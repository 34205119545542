import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  staff;
  articles;
  articlesMap = {};
  strategiesFunds;
  contactLogos;

  constructor(private db: AngularFirestore) {
    // db.firestore.enablePersistence();
    this.loadData();
  }

  public getArticle(id) {
    return this.articlesMap[id];
  }

  private loadData() {
    this.loadStaff();
    this.loadNews();
    this.loadStrategies();
    this.loadContact();
  }

  private loadContact() {
    this.contactLogos = new Promise((res, rej) => {
      const logosDoc = this.db.collection<any>('contact').doc('logos');
      logosDoc.valueChanges().pipe(
        map((logos: any) => {
          return logos.data.filter(l => l.url);
        }),
        take(1)
      )
      .subscribe(s => res(s), e => rej(e));
    });
  }

  private loadStrategies() {
    this.strategiesFunds = new Promise((res, rej) => {
      const fundsDoc = this.db.collection<any>('strategies').doc('funds');
      fundsDoc.valueChanges().pipe(
        map((funds: any) => {
          return {
            equity: funds.equity.filter(f => f.name),
            fixed: funds.fixed.filter(f => f.name),
            structured: funds.structured.filter(f => f.name)
          };
        }),
        take(1)
      )
      .subscribe(s => res(s), e => rej(e));
    });
  }

  private loadStaff() {
    this.staff = new Promise((res, rej) => {
      const staffCollection = this.db.collection<any>('staff');
      staffCollection.valueChanges().pipe(
        map(actions => {
          return actions.filter(s => s.published)
          .sort((a, b) => a.order - b.order)
          .map(s => {
            s.bio = s.bio.replace(/\<p\>\<br\>\<\/p\>/g, '');
            return s;
          });
        }),
        take(1)
      )
      .subscribe(s => res(s), e => rej(e));
    });
  }

  private loadNews() {
    this.articles = new Promise((res, rej) => {
      const articlesCollection = this.db.collection<any>('news-articles');
      articlesCollection.snapshotChanges().pipe(
        map((actions: any) => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          const article = {id, ...data };
          this.articlesMap[id] = article;
          return article;
        }).filter(s => s.published).sort((a, b) => a.order - b.order).reverse())
      )
      .subscribe(s => res(s), e => rej(e));
    });
  }

}
