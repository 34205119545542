<div class="menu-wrap" [class.open]="open">
  <!-- <div class="bg"></div> -->
  <div class="bg-circle"></div>
  <div class="menu">
      <a class="item" (click)="closeMenu()" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">HOME</a>
      <a class="item" (click)="closeMenu()" routerLink="/about-us" routerLinkActive="active">ABOUT US</a>
      <a class="item" (click)="closeMenu()" routerLink="/strategies" routerLinkActive="active">STRATEGIES</a>
      <a class="item" (click)="closeMenu()" routerLink="/news" routerLinkActive="active">NEWS</a>
      <a class="item" (click)="closeMenu()" routerLink="/contact" routerLinkActive="active">CONTACT</a>
  </div>
</div>
<div class="content-wrap">
  <button class="hamburger hamburger--collapse" type="button" (click)="toggleNav()" [class.is-active]="open" [class.show]="!blueHamburger || open">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
  <button class="hamburger hamburger--collapse blue" type="button" (click)="toggleNav()" [class.is-active]="open" [class.show]="blueHamburger && !open">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</div>

