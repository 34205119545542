import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Background {
  Light,
  Dark
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  background = new BehaviorSubject<Background>(Background.Dark);

  constructor() { }

  setBackground(bg: Background) {
    this.background.next(bg);
  }
}
