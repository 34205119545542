import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { NavService, Background } from '../services/nav.service';

declare var window: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  open = false;
  blueHamburger = false;

  constructor(private router: Router, private navService: NavService, private route: ActivatedRoute) {
    this.router.events
    .subscribe(e => {
      if (e instanceof RoutesRecognized) {
        this.open = false;
        this.enableScroll(true);
      }
    });

    this.navService.background
    .subscribe(bg => {
      this.blueHamburger = bg === Background.Light;
    });

  }

  ngOnInit() {
  }

  toggleNav() {
    this.open = !this.open;
    this.enableScroll(!this.open);
  }

  closeMenu() {
    this.open = false;
    this.enableScroll(true);
  }

  private enableScroll(scroll: boolean) {
    if (window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(scroll);
    }
  }

}
