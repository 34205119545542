import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'firm',
    loadChildren: './firm/firm.module#FirmModule'
  },
  {
    path: 'strategies',
    loadChildren: './strategies/strategies.module#StrategiesModule'
  },
  {
    path: 'news',
    loadChildren: './news/news.module#NewsModule'
  },
  {
    path: 'contact',
    loadChildren: './contact/contact.module#ContactModule'
  },
  {
    path: 'about-us',
    loadChildren: './about-us/about-us.module#AboutUsModule'
  },
  {
    path: 'terms',
    loadChildren: './terms/terms.module#TermsModule'
  },
  {
    path: 'ucits',
    loadChildren: './ucits/ucits.module#UcitsModule'
  },
  {
    path: 'privacy',
    loadChildren: './privacy/privacy.module#PrivacyModule'
  },
  {
    path: 'esg',
    loadChildren: './esg/esg.module#EsgModule'
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
